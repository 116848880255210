<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <CasesHeader :c_title="info.title" :c_date="info.date"/>

      <div class="x12 text-big">
        <p>我今年30岁，和先生一起留学美国攻读硕士学位，几年读下来我们都对这里的学术环境和就业发展空间非常满意。毕业后我的先生很快找到了一份很不错的工作，并且幸运地抽中H1B工作签证，我也得以H4的配偶身份留在美国。正当我们以为可以安心等拿绿卡的时候，通过和公司前辈的交流才发现，就算H1B签证将来也不一定能换到绿卡而且需要等待相当长的时间，有的公司前辈等了7年多了还没有获批。因此，我们开始在网上查询是否有其他更快捷的获取绿卡的途径，从而了解到有EB-5投资移民可以办理，国内也有诸多中介公司提供这项服务。于是我们拜托了父母在国内也帮忙了解下是否有可靠的移民中介可以委托。</p>
        <p>我的父亲通过熟人介绍联系上了跃迁出国公司，据说面谈下来觉得值得信任。在美国EB-5投资移民项目上，跃迁公司迄今保持着三个100%的成功率：100%条件绿卡成功率、100%永久绿卡成功率、100%投资还款成功率。同时，我还就跃迁公司推荐的投资项目进行了考察。当时推荐的项目坐落于加州，正好是我所居住的地方。我通过人脉关系了解到该项目及其开发商确实如跃迁公司所宣传具有强大的实力和资金保障，于是放心了，便委托父母在国内委托跃迁公司为我办理移民。</p>
        <p>经沟通，由于我本人并没有经济实力，所以跃迁公司的顾问建议采取父亲赠与的方式。我的父亲一直在正规企业工作，多年来作为高级管理人员累积了可观的收入。在跃迁公司所指派的文档经理的指导下，我的父亲提供了数年来详尽的银行对账单和个人所得税税单等文件证明工作收入的合法积累，并指导了如何将资金馈赠与我的银行转账操作，整个资金链的证明文件非常完整清晰。由于我和家人常居美国，不用回中国面试。在文档经理和律师的指导下，直接将我们H1B和H4的工作类别签证转换为移民身份，其后很快获得了包含工作许可和离境许可的combo卡，很快获得了条件绿卡。</p>
        <p>在拿到绿卡后，我和先生在美国的工作与生活变得愈发便利，想要换工作也不用再烦恼H1B的签证问题。这两年里我们迎来了小宝贝的出生，生活十分幸福，差点忘记了换永久绿卡的事情。幸亏跃迁公司的文档经理及时联系我们，提前三个月就开始准备所有的手续文件，并时间递交了I-829换永久绿卡的申请。我们非常满意跃迁公司一直以来的效率与服务，并且相信他们的承诺，全家都在安心等待永久绿卡的好消息。</p>
        <p>
          <img loading="lazy" class="aligncenter" src="@/assets/Cases/10/1.jpg">
        </p>
      </div>

      <CasesFooter class="mt-5" :index="info.index"/>
    </div>
  </div>

</template>

<script>
import CasesHeader from "@/components/Cases/CasesHeader";
import CasesFooter from "@/components/Cases/CasesFooter";

export default {
  name: "Cases12",
  components: {CasesFooter, CasesHeader},
  data() {
    return {
      info: this.$route.meta,
    }
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>